.wrapper {
    width: 100%;
    border: 6px solid #2bafe5;
    border-radius: 60px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>div {
        width: 90%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
}

.suggestionspace {
    height: 80px;
}

.suggestion {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: background 200ms ease;
    border-radius: 42px;

    img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-right: 30px;
    }

    &:hover {
        background-color: #EDEDED;
    }
}

.buttonbar {
    width: 100%;
    margin-top: 60px;

    @media screen and (max-width: 992px) {
        margin-top: 25px;
    }

    & button,
    & a {
        margin-right: 30px;
    }
}

.topbar {

    @media only screen and (max-width: 992px) {

        .selectionDisplay {
            align-items: baseline;

            img {
                width: 30px;
            }
        }


    }

    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 30px;

    img {
        width: 45px;
        height: auto;
    }

    .selectionDisplay {
        display: flex;
        align-items: center;
        max-width: 75%;
    }
}

.invalid {
    input {
        border-bottom-color: red !important;
    }
    label {
        color: red !important;
    }
}