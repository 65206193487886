@keyframes handmade_logo {
    to {
        background-position-y: -5290px;
    }
}

.logo {
    width: 250px;
    height: 35px;
    background: url(./sprite_sheet.png) -10px -10px;
}

.logo.activated {
    animation: handmade_logo 3s steps(96) forwards;
}

@media only screen and (max-width: 992px) {
    .logo {
        width: 150px;
        height: 35px;
        background: url(./static_logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
    }

    .logo.logo.activated {
        animation: none;
    }
}

.wrapper {
    background-color:  hsl(197, 78%, 40%);
    padding: 6px 15px;
    border-radius: 25px;
}